import {
    FormField,
    Multiselect,
    SelectProps,
    Select,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { userManagerAPI } from 'api';
import { API_URL_PATH_GROUP_AUTOCOMPLETE } from 'constants/urls';
import useFetch from 'hooks/useFetch';

const DeviceGroups = ({
    selectedItem,
    setSelectedItem,
    selectionType,
    disabled,
}: {
    selectedItem: any;
    setSelectedItem: (state: any) => void;
    selectionType: string;
    disabled?: boolean;
}) => {
    const [allDeviceGroups, setAllDeviceGroups] = useState<
        SelectProps.Options | undefined
    >([]);

    const {
        error: getAllGroupsError,
        response: getAllGroupsResponse,
        loading: getAllGroupsLoading,
    } = useFetch(
        {
            axiosInstance: userManagerAPI,
            method: 'GET',
            url: `${API_URL_PATH_GROUP_AUTOCOMPLETE}`,
        },
        { manual: false }
    );

    useEffect(() => {
        if (getAllGroupsResponse && getAllGroupsResponse.groups.length > 0) {
            const allGroups = getAllGroupsResponse?.groups?.map(
                (group: any) => ({
                    label: group,
                    value: group,
                })
            );

            setAllDeviceGroups(allGroups);
        }
    }, [getAllGroupsResponse]);

    return (
      <>
        {selectionType === 'single' ? (
          <FormField
                    label={
                      <span>
                        Device Group <i>- optional</i>{' '}
                      </span>
                    }
                >
            <Select
                        disabled={disabled}
                        selectedOption={selectedItem}
                        onChange={({ detail }: any) =>
                            setSelectedItem(detail.selectedOption)
                        }
                        options={allDeviceGroups}
                        placeholder='Choose an option'
                        selectedAriaLabel='Selected'
                        errorText='Error fetching groups.'
                        statusType={
                            getAllGroupsLoading
                                ? 'loading'
                                : getAllGroupsError
                                    ? 'error'
                                    : 'finished'
                        }
                        empty='No options'
                        filteringType='auto'
                    />
          </FormField>
            ) : (
              <FormField label='Device Group'>
                <Multiselect
                        disabled={disabled}
                        selectedOptions={selectedItem}
                        onChange={({ detail }: any) =>
                            setSelectedItem(detail.selectedOptions)
                        }
                        options={allDeviceGroups}
                        filteringType='auto'
                        placeholder='Choose options'
                        selectedAriaLabel='Selected'
                        errorText='Error fetching groups.'
                        statusType={
                            getAllGroupsLoading
                                ? 'loading'
                                : getAllGroupsError
                                    ? 'error'
                                    : 'finished'
                        }
                    />
              </FormField>
            )}
      </>
    );
};

export default DeviceGroups;
