
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';

import { deviceManagerAPI } from 'api';
import { API_URL_PATH_DM_DEVICE, API_URL_PATH_DM_GROUP } from 'constants/urls';
import { AlertRule, Asset, AssetAlertRuleStatus, DeviceGroup } from 'types/custom';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { getThresholdsOverlaps } from 'utils';


export interface AlertRulesTableContextProps {
    isOnDeviceManagerPage: boolean;
    isInModal: boolean;
    selectedGroup?: DeviceGroup;
    filterByMeasurement?: string;
    filterByThreshold?: string;
    selectedDevices: Asset[];
    disableAllToggles: boolean;
    onRulesChange?: (rules: AlertRule[]) => void,
}

export interface AlertRuleTableContextState { 
    rulesRefetch: () => void, 
    referringCombo: string, 
    items: AlertRule[],
    collectionProps: any,
    groupSpecificItems: AlertRule[],
    groupSpecificCollectionProps: any,
    rulesError: any,
    rulesLoading: boolean,
    rulesFetching: boolean,
    thresholdsOverlaps: string[]
}

const AlertRulesTableContext = createContext<AlertRuleTableContextState>({
    referringCombo: '',
    rulesRefetch: () => {},
    items: [],
    collectionProps: undefined,
    groupSpecificItems: [],
    groupSpecificCollectionProps: undefined,
    rulesError: undefined,
    rulesLoading: false,
    rulesFetching: false,
    thresholdsOverlaps: []
});

export const useAlertRulesTableContext = () => {
    const context = useContext(AlertRulesTableContext);

    if(!context) {
        throw new Error('useAlertRulesTableContext must be used within an AlertRulesTableProvider');
    }

    return context;
}
export const AlertRulesTableProvider = ({ children, props }: { 
    children: React.ReactNode; 
    props: AlertRulesTableContextProps 
}) => {
    const { 
        isOnDeviceManagerPage, 
        isInModal, 
        selectedGroup, 
        filterByMeasurement, 
        filterByThreshold, 
        selectedDevices, 
        disableAllToggles, 
        onRulesChange 
    } = props;

    const [deviceSpecificRules, setDeviceSpecificRules] = useState<AlertRule[]>([]);
    const [groupSpecificRules, setGroupSpecificRules] = useState<AlertRule[]>([]);
    const [thresholdsOverlaps, setThresholdsOverlaps] = useState<string[]>([]);
    const  isDeviceSelected = isOnDeviceManagerPage || isInModal;

    const { items, collectionProps } = useCollection(deviceSpecificRules, {
        sorting: {},
    });

    const { items: groupSpecificItems, collectionProps: groupSpecificCollectionProps } = useCollection(groupSpecificRules, {
        sorting: {},
    });

    const [alertFetchUrl, fetchKey, referringCombo] = useMemo(() => {
        let baseUrl = ''

        if (isDeviceSelected){
            baseUrl = `${API_URL_PATH_DM_DEVICE}/${selectedDevices![0].name}/rules`
        }
        else if (selectedGroup) {
            baseUrl = `${API_URL_PATH_DM_GROUP}/${selectedGroup.groupId}/rules`
        }

        const queryParams = []
        if (filterByMeasurement) queryParams.push(`measure=${filterByMeasurement}`);
        if (filterByThreshold) queryParams.push(`thresholdCode=${filterByThreshold}`);
        if (!selectedGroup && isOnDeviceManagerPage) {
            queryParams.push
                ("includeFromGroups=true")
        }

        const deviceOrGroup = isDeviceSelected ? 'device' : 'group';
        const identificator = isDeviceSelected ? selectedDevices![0].name : selectedGroup?.groupId ?? 'no-group';

        const filterDescription = 
        [filterByMeasurement && `measure:${filterByMeasurement}`, filterByThreshold && `threshold:${filterByThreshold}`]
            .filter(Boolean)
            .join('|') || 'no-filters';

        const finalUrl = `${baseUrl}${queryParams.length ? `?${queryParams.join('&')}` : ''}`;
        const key = `${deviceOrGroup}-${identificator}-${filterDescription}`;

        return [finalUrl, key, `${deviceOrGroup} ${identificator}`];
    }, [isOnDeviceManagerPage, selectedGroup, selectedDevices, filterByMeasurement, filterByThreshold]);

    const {
        data: rulesResponse,
        error: rulesError,
        isLoading: rulesLoading,
        isFetching: rulesFetching,
        status: rulesStatus,
        refetch: rulesRefetch
    } = useFetchWithReactQuery<AlertRule[]>({
        axiosInstance: deviceManagerAPI,
        url: alertFetchUrl,
        key: fetchKey,
        enabled: alertFetchUrl !== ''
    });

    useEffect(() => {
        // For updating this, also ensures that toggles aren't being updated
        if (rulesStatus === 'success' && !disableAllToggles) {
            const allRulesIncludingNewlyCreatedRule = rulesResponse?.filter((rule) => 
                rule.status !== AssetAlertRuleStatus.DELETED) || [];
            const groupRules = allRulesIncludingNewlyCreatedRule.filter(rule => rule.deviceGroupId)
            const deviceSpecificRules = allRulesIncludingNewlyCreatedRule.filter(rule => !rule.deviceGroupId)
            setGroupSpecificRules(groupRules);
            setDeviceSpecificRules(deviceSpecificRules);
            onRulesChange?.(allRulesIncludingNewlyCreatedRule);
            setThresholdsOverlaps(getThresholdsOverlaps(deviceSpecificRules));
        }

        if (alertFetchUrl === "") {
            setGroupSpecificRules([]);
            setDeviceSpecificRules([]);
            onRulesChange?.([]);
        }
    }, [rulesStatus, rulesResponse, alertFetchUrl]);

    const values = useMemo(() => ({
        items: [...items],
        collectionProps,
        groupSpecificItems: [...groupSpecificItems],
        groupSpecificCollectionProps,
        rulesRefetch,
        referringCombo,
        rulesError,
        rulesLoading,
        rulesFetching,
        thresholdsOverlaps,
    }), [
        items,
        collectionProps,
        groupSpecificItems,
        groupSpecificCollectionProps,
        rulesRefetch,
        referringCombo,
        rulesError,
        rulesLoading,
        rulesFetching,
        thresholdsOverlaps,
    ]);

    return (
      <AlertRulesTableContext.Provider value={values}>
        {children}
      </AlertRulesTableContext.Provider>)
};