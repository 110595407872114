import { TreeItem } from "@mui/x-tree-view";
import { useDrop, useDrag } from 'react-dnd';
import { useEffect, useRef, useState } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import { Checkbox, CheckboxProps } from "@cloudscape-design/components";
import AodIcon from '@mui/icons-material/Aod';
import FolderIcon from '@mui/icons-material/Folder';
import { NonCancelableCustomEvent } from "@cloudscape-design/components/internal/events";

import { deviceManagerAPI } from "api";
import { HierarchyNode } from "types/custom";
import { API_URL_PATH_TAG } from "constants/urls";
import { usePageLayoutContext } from "components/common/layout";
import { useDashboardContext } from "providers/DashboardProvider";
import { colors } from "pages/dashboard/dashboard-page/utils";
import useFetch from "hooks/useFetch";

export type HierarchyTreeItemProps = {
  node: HierarchyNode;
  isRootTag: boolean;
  hierarchyId: string;
  isUpdating: React.MutableRefObject<boolean>;

  onNodeDelete: (node: HierarchyNode) => void;
  onDeviceDetach: (node: HierarchyNode) => void;
  onTagRename: (node: HierarchyNode) => void;
  onNodeClick: (node: HierarchyNode) => Promise<void>;
  onNodeAdded: (parent: HierarchyNode) => void;
  onNodeDropped: (source: HierarchyNode, destination: HierarchyNode) => void;
  setDeviceForDetailsModal: (deviceName: string | null) => void;
  setTagForAddModal: (tagId: HierarchyNode | null) => void;
};

const DRAGGABLE_TYPE = 'HIERARCHY_TREE_ITEM';

const HierarchyItem = ({
  node,
  isRootTag,
  hierarchyId,
  isUpdating,
  onNodeClick,
  onNodeDropped,
  onNodeDelete,
  onDeviceDetach,
  onTagRename,
  onNodeAdded,
  setDeviceForDetailsModal,
  setTagForAddModal,
}: HierarchyTreeItemProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isAddingTag, setIsAddingTag] = useState(false);
  
  const { setNotification } = usePageLayoutContext();
  const { setChartDevices, chartDevices, selectedMeasurements, setSelectedMeasurements } = useDashboardContext();

  const ref = useRef(null);
  const moreIconRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { fetchData: createTag } = useFetch({
    axiosInstance: deviceManagerAPI,
    method: 'POST',
    url: API_URL_PATH_TAG,
    data: {
      name: 'New Tag',
      hierarchyId,
      assetList: []
    }
  }, { manual: true });

  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: DRAGGABLE_TYPE,
      drop(_item: any, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }
        onNodeDropped(_item, node);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [node],
  );

  const [, drag] = useDrag(() => ({
    canDrag: () => !isUpdating.current && !isMenuOpen && !isAddingTag,
    type: DRAGGABLE_TYPE,
    item: node,
  }), [node]);

  if (!isRenaming) drag(drop(ref));

  const handleOptionsMenu = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsMenuOpen(!isMenuOpen);
  };

  const handleNodeClick = async (e: React.MouseEvent<HTMLLIElement, MouseEvent>, node: HierarchyNode) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target instanceof HTMLInputElement || isRenaming) return; // Prevent checkbox click from triggering node click
    
    if (node.isDevice) {
      if (chartDevices.length === 1 && chartDevices[0].id === node.id) return; // Already the only selected device, return
      setSelectedMeasurements(new Map());
      const availableColors = colors.filter(color => !chartDevices.some(device => device.color === color));
      setChartDevices([{ ...node, color: availableColors[0] }]);
    }

    await onNodeClick(node);
  };

  const handleRenameNode = () => {
    setIsRenaming(true);
    setIsMenuOpen(false);
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation(); // Prevents Tree View automatic item focus by keyboard navigation
    if (e.key === 'Enter' && isRenaming) {
      setIsRenaming(false);
      await saveNodeName();
    }
  };

  const handleAddTag = async () => {
    if (isAddingTag) return;
    setIsAddingTag(true);

    const tagResponse = await createTag();
    if (!tagResponse) {
      setNotification([{
        type: 'error',
        content: 'Failed to create tag',
      }]);
      return;
    }

    setIsAddingTag(false);

    if (!node.children) node.children = [];
    node.children.push({
      id: tagResponse.data.id,
      name: tagResponse.data.name,
      assetList: [],
      isNew: true,
      children: [],
      isDevice: false,
    });

    onNodeAdded(node);
    setIsMenuOpen(false);
  };

  const saveNodeName = async () => {
    setIsRenaming(false);
    setIsMenuOpen(false);
    if (!node.isDevice) onTagRename(node);
  };

  const handleSelectDevice = (e: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) => {
    const availableColors = colors.filter(color => !chartDevices.some(device => device.color === color));
    if (availableColors.length === 0) {
      setNotification([
        {
          type: 'error',
          content: 'You can only select up to 10 devices',
        },
      ]);
      return;
    }

    if (e.detail.checked && !chartDevices.some(device => device.id === node.id)) {
      setChartDevices([...chartDevices, { ...node, color: availableColors[0] }]);
    } else {
      setChartDevices(chartDevices.filter((device) => device.id !== node.id));
      const newSelectedMeasurements = new Map(selectedMeasurements);
      newSelectedMeasurements.forEach((value, key) => {
        newSelectedMeasurements.set(key, value.filter((device) => device !== node.id));
      });

      setSelectedMeasurements(newSelectedMeasurements);
    }
  };

  useEffect(() => {
    if (isRenaming && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isRenaming, node.children, node.name]);

  useEffect(() => {
    if (node.isNew) {
      setIsRenaming(true);

      node.isNew = false;

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [node]);

  return (
    <div key={node.id}>
      <Menu
        open={isMenuOpen && !isRenaming && !isAddingTag && !isUpdating.current}
        onClose={() => setIsMenuOpen(false)}
        anchorEl={moreIconRef.current}>
        {!node.isDevice &&
          <div>
            <MenuItem onClick={handleAddTag}>Add Tag</MenuItem>
            <MenuItem onClick={() => {
              setTagForAddModal(node || null);
              setIsMenuOpen(false);
            }}>
              Add Device
            </MenuItem>
            <MenuItem onClick={handleRenameNode}>Rename</MenuItem>
            {!isRootTag &&
              <MenuItem onClick={() => {
                onNodeDelete(node);
                setIsMenuOpen(false);
              }}>Delete</MenuItem>
            }
          </div>
        }
        {node.isDevice &&
          <div>
            <MenuItem onClick={() => {
              onDeviceDetach(node);
              setIsMenuOpen(false);
            }}>
              Detach Device
            </MenuItem>
            <MenuItem onClick={() => {
              setDeviceForDetailsModal(node?.id || null);
              setIsMenuOpen(false);
            }}>
              Device Details
            </MenuItem>
          </div>
        }
      </Menu>
      <TreeItem
        ref={ref}
        key={node.id}
        itemId={node.id}
        disabled={!node.name}
        slots={{ icon: node.isDevice ? AodIcon : FolderIcon }}
        sx={{ "& > div": { padding: "2px" } }}
        onFocusCapture={(e) => e.stopPropagation()}
        label={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {isRenaming ? <input
              ref={inputRef}
              defaultValue={node.name}
              onKeyDown={handleKeyDown}
              onBlur={saveNodeName}
              onChange={(e) => node.name = e.target.value} /> :
            <span style={{ display: "flex", alignItems: 'center' }}>
              {node.isDevice &&
                <Checkbox
                    checked={chartDevices.some(device => device.id === node.id)}
                    onChange={handleSelectDevice}
                    />}
              <span style={{ marginLeft: "5px" }}>{node.name || <i>Loading...</i>}</span>
              {isAddingTag && <span style={{ marginLeft: "5px" }}><CircularProgress size={15} style={{ color: 'black' }} /></span>}
            </span>}
            <MoreHorizIcon ref={moreIconRef} color="disabled" onClick={handleOptionsMenu} />
          </div>
        }
        onClick={async (e) => await handleNodeClick(e, node)}
        ContentProps={{
          style: {
            transition: '0.3s all',
            backgroundColor: `${isOverCurrent ? 'lightgrey' : ''}`,
            paddingBottom: isOverCurrent ? '50px' : '0',
          }
        }}
      >
        {Array.isArray(node.children)
          ? node.children.map((childNode) =>
            <HierarchyItem
              key={childNode.id}
              node={childNode}
              isRootTag={false}
              hierarchyId={hierarchyId}
              isUpdating={isUpdating}
              onNodeDropped={onNodeDropped}
              onNodeDelete={onNodeDelete}
              onDeviceDetach={onDeviceDetach}
              onTagRename={onTagRename}
              onNodeAdded={onNodeAdded}
              onNodeClick={onNodeClick}
              setDeviceForDetailsModal={setDeviceForDetailsModal}
              setTagForAddModal={setTagForAddModal} />
          ) : null
        }
      </TreeItem>
    </div>
  );
};

export default HierarchyItem;
