import {
    Modal,
    Box,
    SpaceBetween,
    Button,
    FormField,
    Input,
    Form,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { deviceManagerAPI } from 'api';
import { API_URL_PATH_DM_DEVICE } from 'constants/urls';
import useFetch from 'hooks/useFetch';

const UpdateDeviceModal = ({
    selectedDevice,
    visible,
    onDiscard,
    refetch,
}: {
    selectedDevice: any[];
    visible: boolean;
    onDiscard: () => void;
    refetch: () => void;
}) => {
    const [description, setDescription] = useState(
        selectedDevice[0].description
    );
    const [friendlyName, setFriendlyName] = useState(
        selectedDevice[0].friendlyName
    );

    const {
        status: updateDeviceStatus,
        error: updateDeviceError,
        loading: updateDeviceLoading,
        fetchData: updateDevice,
    } = useFetch(
        {
            axiosInstance: deviceManagerAPI,
            method: 'PATCH',
            url: `${API_URL_PATH_DM_DEVICE}/${selectedDevice[0].name}/attributes`,
            data: {
                description,
                friendlyName,
            },
        },
        { manual: true }
    );

    useEffect(() => {
        if (updateDeviceStatus === 200) {
            refetch();
            onDiscard();
        }
    }, [updateDeviceStatus]);

    return (
      <Modal
            onDismiss={onDiscard}
            visible={visible}
            closeAriaLabel='Close modal'
            footer={
              <Box float='right'>
                <SpaceBetween direction='horizontal' size='xs'>
                  <Button variant='link' onClick={onDiscard}>
                    Cancel
                  </Button>
                  <Button
                            variant='primary'
                            onClick={() => updateDevice()}
                            loading={updateDeviceLoading}
                        >
                    Submit
                  </Button>
                </SpaceBetween>
              </Box>
            }
            header='Update Device'
        >
        <Form errorText={updateDeviceError}>
          <SpaceBetween direction='vertical' size='l'>
            <FormField
                        label={
                          <span>
                            Description <i>- optional</i>{' '}
                          </span>
                        }
                    >
              <Input
                            value={description}
                            onChange={(event) =>
                                setDescription(event.detail.value)
                            }
                        />
            </FormField>
            <FormField label='Friendly Name'>
              <Input
                            value={friendlyName}
                            onChange={(event) =>
                                setFriendlyName(event.detail.value)
                            }
                        />
            </FormField>
          </SpaceBetween>
        </Form>
      </Modal>
    );
};

export default UpdateDeviceModal;
