import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormField, Button, Input, Header, TextContent, Icon } from '@cloudscape-design/components';

import useFetch from 'hooks/useFetch';
import { incidentManagerAPI } from 'api';
import TimePicker from 'components/time-picker';
import { API_URL_PATH_IM_CONTACTS } from 'constants/urls';
import { usePageLayoutContext } from 'components/common/layout';
import { convertLocalHourToUTC, convertUTCHourToLocal } from 'utils';
import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';

interface CreateContactValues {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  startHour: string;
  endHour: string;
  alias: string;
};

type CreateUpdateContactProps = {
  isUpdating: boolean;
  setIsUpdatingContact: (state: boolean) => void;
}

enum CONTACT_CHANNEL {
  EMAIL = 'EMAIL',
  SMS = 'SMS'
};

const CreateUpdateContact = ({ isUpdating, setIsUpdatingContact }: CreateUpdateContactProps) => {
  const { setNotification } = usePageLayoutContext();

  const { contactsRefetch, selectedContact } = useNotificationManagerContext();
  const [isEmailActive, setIsEmailActive] = useState(false);
  const [isMobileActive, setIsMobileActive] = useState(false);

  const [twentyFourHourFormat, setTwentyFourHourFormat] = useState<boolean>(true);

  const createContact = async (values: CreateContactValues) => {
    let response;
    if (isUpdating) {
      response = await createUpdateContact(`${API_URL_PATH_IM_CONTACTS}/${selectedContact?.alias}`, {
        phone: values.mobile || undefined,
        onCall: {
          start: convertLocalHourToUTC(values.startHour, true),
          end: convertLocalHourToUTC(values.endHour, true),
        },
      });
    } else {
      response = await createUpdateContact(API_URL_PATH_IM_CONTACTS, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email || undefined,
        phone: values.mobile || undefined,
        onCall: {
          start: convertLocalHourToUTC(values.startHour, true),
          end: convertLocalHourToUTC(values.endHour, true),
        },
        alias: values.alias,
        active: true,
      });
    }
    if (response && response.status >= 200 && response.status < 300) {
      setIsUpdatingContact(true);
      contactsRefetch();
      setNotification([{
        type: 'success',
        content: `${isUpdating ? 'Updated' : 'Created'} contact ${values.alias} successfully`,
      }]);
      return true;
    } else {
      setNotification([
        {
          type: 'error',
          content: response?.data?.message?.toString() || `Unexpected error occurred when ${isUpdating ? 'updating' : 'creating'} contact ${values.alias}`,
        },
      ]);
      return false;
    }
  };

  const { handleSubmit, setValues, values, errors, touched, setTouched } = useFormik<CreateContactValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      startHour: '',
      endHour: '',
      alias: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email'),
      mobile: Yup.string().matches(/^\+?\d+$/, "Phone number must start with a '+' and contain only numbers"),
      startHour: Yup.string(),
      endHour: Yup.string(),
      alias: Yup.string().required('Alias is required'),
    }),
    onSubmit: createContact,
  });

  const { fetchData: createUpdateContact, loading } = useFetch(
    {
      axiosInstance: incidentManagerAPI,
      method: isUpdating ? 'PATCH' : 'POST',
    },
    { manual: true }
  );

  useEffect(() => {
    if (selectedContact && isUpdating) {
      for (const channel of selectedContact.contactChannels || []) {
        if (channel.type === CONTACT_CHANNEL.EMAIL) {
          setIsEmailActive(channel.active);
        }
        if (channel.type === CONTACT_CHANNEL.SMS) {
          setIsMobileActive(channel.active);
        }
      }

      setValues({
        firstName: String(selectedContact.firstName),
        lastName: String(selectedContact.lastName),
        email: selectedContact.email,
        mobile: selectedContact.phone,
        startHour: String(convertUTCHourToLocal(selectedContact.onCall.start, true) || ''),
        endHour: String(convertUTCHourToLocal(selectedContact.onCall.end, true) || ''),
        alias: String(selectedContact.alias),
      });
    }
  }, [selectedContact, isUpdating, setValues]);

  useEffect(() => {
    if (!isUpdating) {
      setValues({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        startHour: '',
        endHour: '',
        alias: '',
      });
    }
  }, [isUpdating, setValues]);

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: "550px" }}>
      <div style={{ paddingBottom: "20px" }}>
        <Header variant='h3'>
          {isUpdating ? "Update" : "Create"} Contact
        </Header>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <FormField
          label="Alias"
          errorText={touched.alias && errors.alias ? errors.alias : ""}>
          <Input
            name="alias"
            disabled={isUpdating || loading}
            value={values.alias}
            onChange={({ detail }) => setValues({ ...values, alias: detail.value })}
            onBlur={(e) => setTouched({ ...touched, alias: true })}
            onFocus={(e) => setTouched({ ...touched, alias: false })}
            placeholder="Alias" />
        </FormField>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <FormField
          label="First Name"
          errorText={touched.firstName && errors.firstName ? errors.firstName : ""}>
          <Input
            name="firstName"
            disabled={isUpdating || loading}
            value={values.firstName}
            onChange={({ detail }) => setValues({ ...values, firstName: detail.value })}
            onBlur={(e) => setTouched({ ...touched, firstName: true })}
            onFocus={(e) => setTouched({ ...touched, firstName: false })}
            placeholder="First Name" />
        </FormField>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <FormField
          label="Last Name"
          errorText={touched.lastName && errors.lastName ? errors.lastName : ""}>
          <Input
            name="lastName"
            disabled={isUpdating || loading}
            value={values.lastName}
            onChange={({ detail }) => setValues({ ...values, lastName: detail.value })}
            onBlur={(e) => setTouched({ ...touched, lastName: true })}
            onFocus={(e) => setTouched({ ...touched, lastName: false })}
            placeholder="Last Name" />
        </FormField>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <FormField
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              {isUpdating &&
                <Icon
                  name={isEmailActive ? 'status-positive' : 'status-warning'}
                  size='medium'
                  variant={isEmailActive ? 'success' : 'subtle'}
                />
              }
              <span style={{ marginLeft: "5px" }}>Email</span>
            </div>
          }
          errorText={touched.email && errors.email ? errors.email : ""}>
          <Input
            name="email"
            disabled={isUpdating || loading}
            value={values.email}
            onChange={({ detail }) => setValues({ ...values, email: detail.value })}
            onBlur={(e) => setTouched({ ...touched, email: true })}
            onFocus={(e) => setTouched({ ...touched, email: false })}
            placeholder="Email" />
        </FormField>
      </div>

      <div style={{ paddingBottom: "10px" }}>
        <FormField
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              {isUpdating &&
                <Icon
                  name={isMobileActive ? 'status-positive' : 'status-warning'}
                  size='medium'
                  variant={isMobileActive ? 'success' : 'subtle'}
                />
              }
              <span style={{ marginLeft: "5px" }}>Mobile</span>
            </div>
          }
          errorText={touched.mobile && errors.mobile ? errors.mobile : ""}>
          <Input
            name="mobile"
            value={values.mobile}
            disabled={loading}
            onChange={({ detail }) => setValues({ ...values, mobile: detail.value })}
            onBlur={(e) => setTouched({ ...touched, mobile: true })}
            onFocus={(e) => setTouched({ ...touched, mobile: false })}
            placeholder="Mobile" />
        </FormField>
      </div>

      <Switch
        checked={twentyFourHourFormat}
        onChange={() => setTwentyFourHourFormat(prev => !prev)}
      /> {"24 Hour Format"}

      <div style={{ display: "flex", justifyContent: 'space-between', paddingBottom: "10px" }}>
        <FormField
          label="Start Hour"
          constraintText="If empty, from day beginning"
          errorText={touched.startHour && errors.startHour ? errors.startHour : ""}>
          <TimePicker
            use24Hour={twentyFourHourFormat}
            hour={values.startHour}
            loading={loading}
            onChange={(hour) => setValues({ ...values, startHour: hour })}
            onBlur={() => setTouched({ ...touched, startHour: true })}
            onFocus={() => setTouched({ ...touched, startHour: false })}
          />
        </FormField>

        <FormField
          label="End Hour"
          constraintText="If empty, to day ending"
          errorText={touched.endHour && errors.endHour ? errors.endHour : ""}>
          <TimePicker
            use24Hour={twentyFourHourFormat}
            hour={values.endHour}
            loading={loading}
            onChange={(hour) => setValues({ ...values, endHour: hour })}
            onBlur={() => setTouched({ ...touched, endHour: true })}
            onFocus={() => setTouched({ ...touched, endHour: false })}
          />
        </FormField>
      </div>

      <div style={{ paddingBottom: "20px" }}>
        <TextContent>
          Time Zone: <strong>{Intl.DateTimeFormat().resolvedOptions().timeZone}</strong>
        </TextContent>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button formAction="submit" variant="primary" loading={loading}>
          {isUpdating ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  )
};

export default CreateUpdateContact;