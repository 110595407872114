import { useEffect, useState } from 'react';
import { Alert, Box, Button, Modal, SpaceBetween, Spinner } from '@cloudscape-design/components';

import useFetch from 'hooks/useFetch';
import { incidentManagerAPI } from 'api';
import { API_URL_PATH_IM_CONTACTS } from 'constants/urls';
import ListItemComp from 'components/incident-manager/ListItem';
import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';
import { usePageLayoutContext } from 'components/common/layout';

type ContactListProps = {
    setIsUpdatingContact: (state: boolean) => void;
};

const ContactsList = ({ setIsUpdatingContact }: ContactListProps) => {
    const { setNotification } = usePageLayoutContext();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [contactToDelete, setContactToDelete] = useState<any>();
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const {
        allContacts,
        setSelectedContact,
        contactsRefetch,
        contactsLoading
    } = useNotificationManagerContext();

    const { loading: updateContactLoading, status: updateContactStatus, fetchData: updateContact } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'PATCH',
        },
        { manual: true }
    );

    const { loading: deleteContactLoading, status: deleteContactStatus, fetchData: deleteContact } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'DELETE',
        },
        { manual: true }
    );

    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
        setIsUpdatingContact(true);
    };

    const handleDisableContact = (event: React.ChangeEvent<HTMLInputElement>, contact: any) => {
        updateContact(`${API_URL_PATH_IM_CONTACTS}/${contact.alias}`, {
            active: !contact.active,
        });
    };

    const showDeleteModal = (contact: any) => {
        setContactToDelete(contact);
        setDeleteModalVisible(true);
    };

    useEffect(() => {
        if (allContacts) {
            setSelectedContact(allContacts[0]);
            setSelectedIndex(0);
        }
    }, [allContacts]);

    useEffect(() => {
        if (!deleteContactLoading && deleteContactStatus) {
            setDeleteModalVisible(false);
            contactsRefetch();
            if (deleteContactStatus === 200) {
                setNotification([{
                    type: 'success',
                    content: 'Contact deleted successfully',
                }]);
            } else {
                setNotification([{
                    type: 'error',
                    content: 'Error deleting contact',
                }]);
            }
        }
    }, [deleteContactLoading, deleteContactStatus]);

    useEffect(() => {
        if (!updateContactLoading && updateContactStatus) {
            contactsRefetch();
            if (updateContactStatus === 200) {
                setNotification([{
                    type: 'success',
                    content: 'Contact updated successfully',
                }]);
            } else {
                setNotification([{
                    type: 'error',
                    content: 'Error updating contact',
                }]);
            }
        }
    }, [updateContactLoading, updateContactStatus]);

    return (
        <>
            {contactsLoading && <Spinner />}
            {(updateContactLoading || deleteContactLoading) && <Spinner />}
            {allContacts && allContacts.length === 0 && (
                <Alert>No contacts found</Alert>
            )}

            <Modal
                onDismiss={() => setDeleteModalVisible(false)}
                header='Delete Contact'
                visible={deleteModalVisible || deleteContactLoading}
                closeAriaLabel='Close modal'
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={() => setDeleteModalVisible(false)} disabled={deleteContactLoading}>
                                Cancel
                            </Button>
                            <Button variant='primary' onClick={() => deleteContact(`${API_URL_PATH_IM_CONTACTS}/${contactToDelete?.alias}`)} loading={deleteContactLoading}>
                                Delete
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <div>
                    Are you sure you want to delete {" " + contactToDelete?.alias} permanently?
                    <i>You can't undo this action.</i>
                </div>
            </Modal>

            <ListItemComp
                allItems={allContacts}
                selectedIndex={selectedIndex}
                handleListItemClick={handleListItemClick}
                setSelectedItem={setSelectedContact}
                module='contacts'
                disableContact={handleDisableContact}
                isLoading={updateContactLoading || deleteContactLoading}
                removeContact={showDeleteModal}
            />
        </>
    );
};

export default ContactsList;
