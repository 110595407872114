import {
    Box,
    Button,
    Modal,
    SpaceBetween,
} from '@cloudscape-design/components';

const DeleteModal = ({
    visible,
    areActionsDisabled,
    onDiscard,
    onDelete,
    itemName,
    moduleName,
    loading,
}: {
    visible: boolean;
    areActionsDisabled?: boolean;
    onDiscard: () => void;
    onDelete: () => void;
    itemName: any[] | string[] | undefined;
    itemCount: any;
    moduleName: string;
    loading?: boolean;
}) => {
    const names = (itemName: any[] | undefined) =>
        itemName?.map((item) => typeof item === 'string' ? item : (item?.name || item?.friendlyName));

    return (
      <>
        <Modal
                onDismiss={onDiscard}
                visible={visible}
                closeAriaLabel='Close modal'
                footer={
                  <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                      <Button variant='link' onClick={onDiscard} disabled={areActionsDisabled}>
                        Cancel
                      </Button>
                      <Button variant='primary' onClick={onDelete} loading={loading} disabled={areActionsDisabled}>
                        Delete
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
                header={`Delete ${moduleName}`}
            >
          Are you sure you want to delete the following{' '}
          {moduleName.toLowerCase()} permanently?{' '}
          <div>
            <i>You can't undo this action.</i>
          </div>{' '}
          <b>
            <ul>
              {names(itemName)?.map(name => <li key={name}>{name}</li>)}
            </ul>
          </b>
        </Modal>
      </>
    );
};

export default DeleteModal;
