import {
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCollection } from '@cloudscape-design/collection-hooks';

import { usePageLayoutContext } from 'components/common/layout';
import { EmptyState } from 'components/empty-state/EmptyState';
import { API_URL_PATH_GROUP_LIST } from 'constants/urls';
import { useDeviceGroupsContext } from 'pages/device-group-manager/DeviceGroupListPage';
import { getMatchesCountText } from 'utils';
import { userManagerAPI } from 'api';
import useFetch from 'hooks/useFetch';
import DeleteModal from 'components/delete-modal';

import { COLUMN_DEFINITIONS } from './table-config';
import AddRemoveAssetModal from '../AddRemoveAssetModal';
import UpdateDeviceGroupModal from '../UpdateDeviceGroupModal';


const DeviceGroupTable = () => {
    const navigate = useNavigate();
    const [showAddRemoveModal, setShowAddRemoveModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [action, setAction] = useState('');

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onDeleteDiscard = () => setShowDeleteModal(false);

    const onAddRemoveDiscard = () => setShowAddRemoveModal(false);
    const onUpdateDiscard = () => setShowUpdateModal(false);

    const {
        allGroups,
        isLoading,
        isFetching,
        setSelectedGroup,
        selectedGroup,
        refetch,
    } = useDeviceGroupsContext();

    const { setNotification } = usePageLayoutContext();

    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        filterProps,
        paginationProps,
    } = useCollection(allGroups, {
        filtering: {
            empty: (
              <EmptyState
                    title='No instances'
                    subtitle='No instances to display.'
                    action={
                      <Button
                            onClick={() =>
                                navigate('/device-group-manager/create', {
                                    state: {
                                        action: 'create',
                                    },
                                })
                            }
                        >
                        Create a Device Group
                      </Button>
                    }
                />
            ),
            noMatch: (
              <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={
                      <Button onClick={() => actions.setFiltering('')}>
                        Clear filter
                      </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: 10 },
        sorting: {},
    });

    useEffect(() => {
        setSelectedGroup([allGroups[0]] || []);
    }, [allGroups]);

    // const {
    //     response: cleanupAssetResponse,
    //     error: cleanupAssetError,
    //     loading: cleanupAssetLoading,
    //     status: cleanupAssetStatus,
    //     fetchData: cleanupAsset,
    // } = useFetch(
    //     {
    //         axiosInstance: userManagerAPI,
    //         method: 'PUT',
    //         url: `${API_URL_PATH_GROUP_LIST}/${selectedGroup[0]?.groupId}/cleanupassets`,
    //     },
    //     { manual: true }
    // );

    const {
        fetchData: deleteDeviceGroup,
        error: deleteDeviceGroupError,
    } = useFetch(
        {
            axiosInstance: userManagerAPI,
            method: 'DELETE',
            url: `${API_URL_PATH_GROUP_LIST}/${selectedGroup[0]?.groupId}`,
        },
        { manual: true }
    );

    const handleButtonDropdownClick = (event: any) => {
        event.preventDefault();

        if (event.detail.id === 'add-asset') {
            setAction('Add');
            setShowAddRemoveModal(true);
        } else if (event.detail.id === 'remove-asset') {
            setAction('Remove');
            setShowAddRemoveModal(true);
        }
        // else if (event.detail.id === 'cleanup-asset') {
        //     cleanupAsset();
        // }
        else if (event.detail.id === 'edit-group') {
            setShowUpdateModal(true);
        } else if (event.detail.id === 'delete-group') {
            setShowDeleteModal(true);
        }
    };

    const onDeleteConfirm = () => {
        deleteDeviceGroup()
            .then((response) => {
                if (response && (response.status === 200 || response.status === 204)) {
                    refetch();
                    setNotification([
                        {
                            type: 'success',
                            content: `Deleted device group ${selectedGroup![0]?.groupId} successfully`,
                        },
                    ]);
                    setSelectedGroup([]);
                    setShowDeleteModal(false);
                } else {
                    setNotification([
                        {
                            type: 'error',
                            content: deleteDeviceGroupError || 'Bad request',
                        },
                    ]);
                    setShowDeleteModal(false);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
      <>
        <Table
                {...collectionProps}
                onSelectionChange={({ detail }) =>
                    setSelectedGroup(detail.selectedItems)
                }
                selectedItems={selectedGroup}
                loading={isLoading}
                wrapLines={true}
                stripedRows={true}
                columnDefinitions={COLUMN_DEFINITIONS}
                items={items}
                variant='container'
                loadingText='Loading resources'
                selectionType='single'
                // trackBy='groupId'
                filter={
                  <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                        filteringPlaceholder='Find resources'
                    />
                }
                header={
                  <Header
                        counter={
                            selectedGroup?.length && selectedGroup[0]?.groupId
                                ? '(' +
                                  selectedGroup.length +
                                  `/${allGroups && allGroups.length})`
                                : `(${allGroups && allGroups.length})`
                        }
                        actions={
                          <SpaceBetween direction='horizontal' size='xs'>
                            <Button iconName='refresh' onClick={() => refetch()} loading={isFetching} />
                            <Button
                                    onClick={() =>
                                        navigate(
                                            '/device-group-manager/create',
                                            {
                                                state: {
                                                    action: 'create',
                                                },
                                            }
                                    )
                                    }
                                >
                              Create
                            </Button>
                            <ButtonDropdown
                                    items={[
                                        {
                                            text: 'Update description',
                                            id: 'edit-group',
                                            disabled: allGroups?.length === 0,
                                        },
                                        {
                                            text: 'Delete',
                                            id: 'delete-group',
                                            disabled: allGroups?.length === 0,
                                        },
                                        {
                                            text: 'Add devices',
                                            id: 'add-asset',
                                            disabled: allGroups?.length === 0,
                                        },
                                        {
                                            text: 'Remove devices',
                                            id: 'remove-asset',
                                            disabled: allGroups?.length === 0,
                                        },
                                        // {
                                        //     text: 'Cleanup asset',
                                        //     id: 'cleanup-asset',
                                        //     disabled: allGroups?.length === 0,
                                        // },
                                    ]}
                                    onItemClick={handleButtonDropdownClick}
                                >
                              Actions
                            </ButtonDropdown>
                          </SpaceBetween>
                        }
                    >
                    Device Groups
                  </Header>
                }
                pagination={
                  <Pagination
                        {...paginationProps}
                        ariaLabels={{
                            nextPageLabel: 'Next page',
                            previousPageLabel: 'Previous page',
                            pageLabel: (pageNumber) =>
                                `Page ${pageNumber} of all pages`,
                        }}
                    />
                }
            />

        {showAddRemoveModal && (
          <AddRemoveAssetModal
                    selectedGroup={selectedGroup}
                    visible={showAddRemoveModal}
                    onDiscard={onAddRemoveDiscard}
                    action={action}
                    refetch={refetch}
                />
            )}

        {showUpdateModal && (
          <UpdateDeviceGroupModal
                    selectedGroup={selectedGroup}
                    visible={showUpdateModal}
                    onDiscard={onUpdateDiscard}
                    refetch={refetch}
                />
            )}

        <DeleteModal
                visible={showDeleteModal}
                onDiscard={onDeleteDiscard}
                onDelete={onDeleteConfirm}
                itemName={selectedGroup}
                itemCount={selectedGroup?.length}
                moduleName='Device Group'
            />
      </>
    );
};

export default DeviceGroupTable;
