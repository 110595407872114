import {
    Alert,
    FormField,
    Select,
    SpaceBetween,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import React, { useEffect } from 'react';

import {
    API_URL_UM_RESOURCE_REGIONS,
    API_URL_UM_RESOURCE_COUNTRIES,
    API_URL_UM_RESOURCE_SITES,
    API_URL_UM_RESOURCE_BUILDINGS,
    API_URL_UM_RESOURCE_FLOORS,
    API_URL_UM_RESOURCE_ROOMS,
    API_URL_PATH_LOCATIONS_HIERARCHY,
} from 'constants/urls';
import { locationAPI, deviceManagerAPI } from 'api';
import { addRecentlyUsed, useLocationOptions } from 'utils';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';

interface LocationProps {
    region: OptionDefinition | null;
    country: OptionDefinition | null;
    site: OptionDefinition | null;
    building?: OptionDefinition | null;
    floor?: OptionDefinition | null;
    room?: OptionDefinition | null;
    disableFormFields: boolean;
    regionError?: string;
    countryError?: string;
    siteError?: string;
    setRegion: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setCountry: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setSite: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setBuilding?: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setFloor?: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setRoom?: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    deepLocations: boolean;
    hasSubmitted?: boolean;
};

const Location = ({
    region,
    country,
    site,
    building,
    floor,
    room,
    disableFormFields,
    regionError,
    countryError,
    siteError,
    setRegion,
    setCountry,
    setSite,
    setBuilding,
    setFloor,
    setRoom,
    deepLocations,
    hasSubmitted,
}: LocationProps) => {
    const { data: storedData, error: storedError } = useFetchWithReactQuery(
        {
            key: 'locations-hierarchy',
            axiosInstance: deviceManagerAPI,
            url: API_URL_PATH_LOCATIONS_HIERARCHY,
        },
    );

    const { data: regionsData, error: regionsError, isLoading: regionsLoading } = useFetchWithReactQuery(
        {
            key: `regions`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_REGIONS}?withchild=true`,
        },
    );

    const { data: countriesData, error: countriesError, isLoading: countriesLoading } = useFetchWithReactQuery(
        {
            key: `countries-${region?.value}`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_COUNTRIES}/${region?.value}?withchild=true`,
            enabled: !!region?.value,
        },
    );

    const { data: sitesData, error: sitesError, isLoading: sitesLoading } = useFetchWithReactQuery(
        {
            key: `sites-${country?.value}`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_SITES}/${country?.value}?withchild=true`,
            enabled: !!country?.value,
        },
    );

    const { data: buildingData, error: buildingError, isLoading: buildingLoading } = useFetchWithReactQuery(
        {
            key: `buildings-${site?.value}`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_BUILDINGS}/${site?.value}?withchild=true`,
            enabled: !!site?.value,
        },
    );

    const { data: floorsData, error: floorsError, isLoading: floorsLoading } = useFetchWithReactQuery(
        {
            key: `floors-${building?.value}`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_FLOORS}/${building?.value}?withchild=true`,
            enabled: !!building?.value,
        },
    );

    const { data: roomsData, error: roomsError, isLoading: roomsLoading } = useFetchWithReactQuery(
        {
            key: `rooms-${floor?.value}`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_ROOMS}/${floor?.value}?withchild=true`,
            enabled: !!floor?.value,
        },
    );

    const allRegions = useLocationOptions(regionsData, 'region');
    const allCountries = useLocationOptions(countriesData, 'country');
    const allSites = useLocationOptions(sitesData, 'site');
    const allBuildings = useLocationOptions(buildingData, 'building');
    const allFloors = useLocationOptions(floorsData, 'floor');
    const allRooms = useLocationOptions(roomsData, 'room');

    useEffect(() => {
        if (allRegions.length > 0 && region?.label === undefined) {
            setRegion(allRegions.find((item) => item.value === region?.value) || null);
        }
        if (allCountries.length > 0 && country?.label === undefined) {
            setCountry(allCountries.find((item) => item.value === country?.value) || null);
        }
        if (allSites.length > 0 && site?.label === undefined) {
            setSite(allSites.find((item) => item.value === site?.value) || null);
        }
    }, [allRegions, allCountries, allSites]);

    return (
      <SpaceBetween direction='vertical' size='l'>
        <FormField label='Region' errorText={regionsError?.message || (hasSubmitted && regionError)}>
          <Select
                    selectedOption={region}
                    onChange={({ detail }) => setRegion(detail.selectedOption)}
                    placeholder='Choose an option'
                    loadingText='Loading regions'
                    options={addRecentlyUsed(allRegions, storedData, [], regionsLoading)}
                    statusType={regionsLoading ? 'loading' : 'finished'}
                    disabled={disableFormFields}
                    empty='No regions found'
                    filteringType='auto'
                />
        </FormField>
        <FormField label='Country' errorText={countriesError?.message || (hasSubmitted && countryError)}>
          <Select
                    selectedOption={country}
                    onChange={({ detail }) => setCountry(detail.selectedOption)}
                    placeholder='Choose an option'
                    loadingText='Loading countries'
                    options={addRecentlyUsed(allCountries, storedData, [region?.value], countriesLoading)}
                    statusType={countriesLoading ? 'loading' : 'finished'}
                    disabled={!region || disableFormFields}
                    empty='No countries found'
                    filteringType='auto'
                />
        </FormField>
        <FormField label='Site' errorText={sitesError?.message || (hasSubmitted && siteError)}>
          <Select
                    selectedOption={site}
                    onChange={({ detail }) => setSite(detail.selectedOption)}
                    placeholder='Choose an option'
                    loadingText='Loading sites'
                    options={addRecentlyUsed(allSites, storedData, [region?.value, country?.value], sitesLoading)}
                    statusType={sitesLoading ? 'loading' : 'finished'}
                    disabled={!country || disableFormFields}
                    empty='No sites found'
                    filteringType='auto'
                />
        </FormField>
        {deepLocations && (
          <>
            <FormField label='Building' errorText={buildingError?.message}>
              <Select
                            selectedOption={building || null}
                            onChange={({ detail }) => setBuilding?.(detail.selectedOption)}
                            placeholder='Choose an option'
                            loadingText='Loading buildings'
                            options={addRecentlyUsed(
                                allBuildings,
                                storedData,
                                [region?.value, country?.value, site?.value],
                                 buildingLoading)}
                            statusType={buildingLoading ? 'loading' : 'finished'}
                            disabled={!site || disableFormFields}
                            empty='No buildings found'
                            filteringType='auto'
                        />
            </FormField>
            <FormField label='Floor' errorText={floorsError?.message}>
              <Select
                            selectedOption={floor || null}
                            onChange={({ detail }) => setFloor?.(detail.selectedOption)}
                            placeholder='Choose an option'
                            loadingText='Loading floors'
                            options={addRecentlyUsed(
                                allFloors,
                                 storedData,
                                [region?.value, country?.value, site?.value, building?.value],
                                floorsLoading)}
                            statusType={floorsLoading ? 'loading' : 'finished'}
                            disabled={!building || disableFormFields}
                            empty='No floors found'
                            filteringType='auto'
                        />
            </FormField>
            <FormField label='Room' errorText={roomsError?.message}>
              <Select
                            selectedOption={room || null}
                            onChange={({ detail }) => setRoom?.(detail.selectedOption)}
                            placeholder='Choose an option'
                            loadingText='Loading rooms'
                            options={addRecentlyUsed(
                                allRooms,
                                storedData,
                                [region?.value, country?.value, site?.value, building?.value, floor?.value],
                                roomsLoading)}
                            statusType={roomsLoading ? 'loading' : 'finished'}
                            disabled={!floor || disableFormFields}
                            empty='No rooms found'
                            filteringType='auto'
                        />
            </FormField>
          </>
            )}
        {storedError && <Alert type="warning" header='Error loading recently used locations'>{storedError.message}</Alert>}
      </SpaceBetween>
    );
};

export default Location;
