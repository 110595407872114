import { ColumnLayout, Box, SpaceBetween } from '@cloudscape-design/components';

import { useUserManagerContext } from 'pages/role-manager/RolesListPage';

const RoleDetails = () => {
    const { selectedItem } = useUserManagerContext();
    return (
      <ColumnLayout columns={2} variant='text-grid'>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Description</Box>
            <div>{selectedItem![0]?.description}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>IDP Group</Box>
            <div>{selectedItem![0]?.idpgroup}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>Resource Type</Box>
            <div>{selectedItem![0]?.resourcetype}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>ID</Box>
            <div>{selectedItem![0]?.id}</div>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Resource</Box>
            <div>{selectedItem![0]?.resource || '-'}</div>
          </div>
          <div>
            <Box variant='awsui-key-label'>Sensor Groups</Box>
            <div>{selectedItem![0]?.groupIds?.join(', ') || '-'}</div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    );
};

export default RoleDetails;
