import { useEffect, useMemo, useState } from 'react';
import { Button, Modal, NonCancelableCustomEvent, Select, SelectProps } from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

import { measurementsToOptions } from 'utils';
import { deviceManagerAPI } from 'api';
import { Asset, DeviceGroup } from 'types/custom';
import DeviceTable from 'components/device-manager/DeviceTable';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { DeviceManagerContext } from 'pages/device-manager/DeviceListPage';
import { API_URL_PATH_DM, API_URL_PATH_DM_DEVICES } from 'constants/urls';
import AlertsTable from 'components/device-manager/DeviceAlertMgr/AlertsTable';
import { useAlertRulesManagerContext } from 'providers/AlertRulesManagerProvider';
import { ThresholdPicker } from 'components/device-manager/DeviceAlertMgr/CreateAlertModal/ThresholdPicker';
import { AlertRulesTableProvider } from 'providers/AlertRulesTableProvider';

const AlertRulesManagerPage = () => {
  const [measurementOptions, setMeasurementOptions] = useState<OptionDefinition[]>([]);
  const [selectedMeasurementOption, setSelectedMeasurementOption] = useState<OptionDefinition | null>(null);
  const [selectedGroupOption, setSelectedGroupOption] = useState<OptionDefinition>({ label: 'All', value: '' });
  const [selectedThreshold, setSelectedThreshold] = useState<string>('');
  const [deviceIdsFromSelectedGroup, setDeviceIdsFromSelectedGroup] = useState<string[]>([]);
  
  const {
    allGroups,
    isGroupsLoading,
    setSelectedGroup,
    selectedGroup,
    allDevices,
    setAllDevices,
    selectedDevices,
    nonDuplicateMeasurements,
    setSelectedDevices,
  } = useAlertRulesManagerContext();

  const showDeviceModalEnabled = selectedGroupOption.value === '';

  const allGroupOptions = useMemo<OptionDefinition[]>(() => {
    const groupOptions = allGroups.map((el: DeviceGroup) => ({ label: el.groupId, value: el.groupId }));
    groupOptions.unshift({ label: 'All', value: '' });

    if(!isGroupsLoading && allGroups.length === 0)
    {
      setSelectedGroupOption(groupOptions[0]);
    }
    else{
      setSelectedGroupOption({ label: selectedGroup?.groupId ?? "All", value: selectedGroup?.groupId ?? ""});
      const deviceIds = selectedGroup?.assets?.map((x: { assetId: string }) => x.assetId) ?? [];
      setDeviceIdsFromSelectedGroup(deviceIds);
    } 
    return groupOptions;
  }, [allGroups, isGroupsLoading]);

  const devicesQueryParams = ['countAlertRules=true'];
  const url = selectedGroupOption.value !== "" ? `${API_URL_PATH_DM_DEVICES}/${deviceIdsFromSelectedGroup.join(',')}` : `${API_URL_PATH_DM}/device/list`;
  const { data: deviceResponse, refetch: deviceResponseRefetch, isFetching: isFetchingDevices, status: deviceResponseStatus } =
    useFetchWithReactQuery<Asset[] | { items: Asset[] }>({
      key: `device-group-devices-${selectedGroupOption.value}`,
      axiosInstance: deviceManagerAPI,
      url: `${url}?${devicesQueryParams.join('&')}`,
      placeholderData: [],
      enabled: deviceIdsFromSelectedGroup.length > 0
  });

  const setMeasurements = () => {
    const options = measurementsToOptions(nonDuplicateMeasurements)
    setMeasurementOptions(options);
  }

  useEffect(() => {
    if(deviceResponseStatus === 'success') {
      if(Array.isArray(deviceResponse)) {
        setAllDevices(deviceResponse);
        setMeasurements()
      } else {
        setAllDevices(deviceResponse.items);
        setMeasurements()
      }
    }
  }, [deviceResponseStatus, deviceResponse, nonDuplicateMeasurements]);

  const onGroupChange = (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
    const selectedGroupOption = event.detail.selectedOption;
    const foundGroup = allGroups.find((group: DeviceGroup) => group.groupId === selectedGroupOption?.value);
    const deviceIds = foundGroup?.assets?.map((x: { assetId: string }) => x.assetId) ?? [];
    setSelectedGroupOption(selectedGroupOption);
    setSelectedGroup(foundGroup);
    setDeviceIdsFromSelectedGroup(deviceIds);
    setSelectedDevices([])
  }

  const contextProps = {
    isOnDeviceManagerPage: false,
    isInModal: false,
    selectedDevices,
    disableAllToggles: false,
    filterByMeasurement: selectedMeasurementOption?.value,
    filterByThreshold: selectedThreshold,
    selectedGroup: selectedGroup,
  }

  return (
    <>
      <div style={{ maxWidth: '230px', marginBottom: '20px',  marginTop: '50px' }}>
        <Select
          inlineLabelText="Device Group"
          options={allGroupOptions}
          selectedOption={selectedGroupOption}
          loadingText="Loading groups"
          placeholder="Choose a group"
          statusType={isGroupsLoading ? "loading" : 'finished'}
          onChange={onGroupChange}
        />
      </div>

      <DeviceManagerContext.Provider
        value={{
          deviceLoading: isFetchingDevices,
          allDevices,
          getAllDevices: deviceResponseRefetch,
          setSelectedDevices,
          selectedDevices,
          deviceError: null,
          activeTabId: '',
          setActiveTabId: () => { },
          deviceFetching: false,
          syncDevices: (async () => { }) as any,
          isSyncingDevices: false,
          countAlertRules: true,
        }}
      >
        <DeviceTable selectionType={showDeviceModalEnabled ? "multi" : undefined} showDeviceModalEnabled={true}/>
      </DeviceManagerContext.Provider>

      {allDevices.length > 0 && (
        <>
          <div>
            <div
              style={{
                display: 'flex',
                gap: '13px',
                alignItems: 'center',
                paddingTop: '20px',
                paddingBottom: '20px',
                height: '100%',
              }}>
              <div style={{ width: '230px' }}>
                <Select
                  inlineLabelText="Measurement"
                  placeholder='Select a measurement'
                  empty='No options'
                  options={measurementOptions}
                  selectedOption={selectedMeasurementOption}
                  onChange={(event) => setSelectedMeasurementOption(event.detail.selectedOption)}
                  disabled={!selectedGroup}
                />
              </div>
              <div style={{
                paddingTop: '10px'
              }}>
                <ThresholdPicker 
                 disabled={!selectedMeasurementOption}
                 selectedId={selectedThreshold}
                 onChange={setSelectedThreshold} />
              </div>
              <div style={{
                paddingTop: '10px'
              }}>
                <Button onClick={() => {
                  setSelectedThreshold('');
                  setSelectedMeasurementOption(null);
                }}
                  iconName='remove'
                  variant='icon'
                  iconAlt='Clear'
                  disabled={!selectedMeasurementOption}
                />
              </div>
            </div>
          </div>
      
          <div style={{ marginBottom: '20px' }}>
            <AlertRulesTableProvider props={contextProps}>
              <AlertsTable
                selectedDevices={selectedDevices}
                loading={isFetchingDevices}
                measurements={nonDuplicateMeasurements}
              />
            </AlertRulesTableProvider>
          </div>
        </>
      )}

    </>
  );
};

export default AlertRulesManagerPage;
